<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="4" sm="4" md="4" lg="2">
          Type
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-radio-group row v-model="params.type">
            <v-radio label="PO" value="PO"></v-radio>
            <v-radio label="DO" value="DO"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="4" lg="2">
          {{ params.type === "PO" ? "PO#" : "DO#" }}
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-autocomplete
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            return-object
            v-model="purchaseOrder"
            :items="purchaseOrders"
            :rules="[v => !!v || 'PO is required']"
            :filter="filterObject"
            @change="onChangePurchaseOrder"
            v-if="params.type === 'PO'"
          >
            <template v-slot:selection="data">
              {{ data.item.purchaseOrderNumber }}
            </template>
            <template v-slot:item="data">
              <v-list-item v-on="data.on">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.purchaseOrderNumber }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-autocomplete
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            item-text="name"
            return-object
            v-model="deliveryOrder"
            :items="deliveryOrders"
            :filter="filterObjectDo"
            :rules="[v => !!v || 'DO is required']"
            @change="onChangeDeliveryOrder"
            v-if="params.type === 'DO'"
          >
            <template v-slot:item="data">
              <v-list-item v-on="data.on">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.ref_do"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2" sm="2" md="2" lg="1">
          <v-btn color="success" @click="search"> <v-icon>mdi-magnify</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" dense>
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.invoiceDate="{ item }">
          {{ formatDate(item.invoiceDate) }}
        </template>
        <template v-slot:item.total="{ item }">
          {{ formatPrice(item.total) }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Invoice Number",
        value: "invoiceNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Customer",
        value: "customerName",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Invoice Date",
        value: "invoiceDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Percentage",
        value: "percentage",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "total",
        value: "total",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "type",
        value: "type",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "PO#",
        value: "poNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 8,
        text: "PO Date",
        value: "poDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 9,
        text: "DO#",
        value: "doNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 10,
        text: "DO Date",
        value: "doDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 11,
        text: "DO Status",
        value: "doStatus",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 12,
        text: "Faktur#",
        value: "taxSerialNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 13,
        text: "Site",
        value: "site",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    items: [],
    purchaseOrders: [],
    deliveryOrders: [],
    purchaseOrder: {},
    deliveryOrder: {},
    params: {
      id: null,
      type: "PO",
    },
    listType: ["PO", "DO"],
  }),

  methods: {
    search() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("saleInvoice/queryInvoice", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
    async getPurchaseOrders() {
      await this.$store
        .dispatch("external/getSalePurchaseOrderAll", true)
        .then(response => (this.purchaseOrders = response.data));
    },
    async getDeliveryOrders() {
      await this.$store
        .dispatch("external/getSaleDeliveryOrdersAll")
        .then(response => (this.deliveryOrders = response.data));
    },
    getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getPurchaseOrders(), this.getDeliveryOrders()])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    // eslint-disable-next-line no-unused-vars
    filterObjectDo(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.ref_do.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    filterObject(item, queryText) {
      return (
        item.purchaseOrderNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    onChangePurchaseOrder(val) {
      this.params.id = val.id;
    },
    onChangeDeliveryOrder(val) {
      this.params.id = val.id;
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
